import { useEffect, useRef } from 'react';
import { addToOpenedTabIds, removeOpenedTabId } from 'utils';

export const useOpenedTabIds = () => {
  const tabIdRef = useRef(Math.random().toString(16).slice(2));

  useEffect(() => {
    addToOpenedTabIds(tabIdRef.current);

    const removeOpened = () => {
      removeOpenedTabId(tabIdRef.current);
    };

    window.addEventListener('beforeunload', removeOpened, false);

    return () => {
      window.removeEventListener('beforeunload', removeOpened, false);
    }
  }, []);
}; 