import dayjs from 'dayjs';

export const DISCOUNT_MODAL_SETTINGS_KEY = 'DISCOUNT_MODAL_SETTINGS';
export const DISCOUNT_MODAL_SETTINGS_KEYS = [
  'shownAt',
  'activeTab',
  'spendEnoughTime',
  'openedTabs',
  'visitedPathnames',
  'isQuoteSubmitted',
  'searchHasResult',
] as const;

export const getDiscountModalSettings = (key?: typeof DISCOUNT_MODAL_SETTINGS_KEYS[number]) => {
  try {
    const settings = JSON.parse(localStorage.getItem(DISCOUNT_MODAL_SETTINGS_KEY) || '{}');
    return key ? settings?.[key] : settings;
  } catch (err) {
    return {};
  }
}

export const setDiscountModalSettings = (key: typeof DISCOUNT_MODAL_SETTINGS_KEYS[number], value: string) => {
  const settings = getDiscountModalSettings();
  settings[key] = value;
  localStorage.setItem(DISCOUNT_MODAL_SETTINGS_KEY, JSON.stringify(settings));
}

export const removeDiscountModalSettings = (key: typeof DISCOUNT_MODAL_SETTINGS_KEYS[number]) => {
  const settings = getDiscountModalSettings();
  delete settings[key];
  localStorage.setItem(DISCOUNT_MODAL_SETTINGS_KEY, JSON.stringify(settings));
}

export const discountModalNotShownRecently = () => {
  const timestamp = parseInt(`0${getDiscountModalSettings('shownAt')}`);
  const lastShownDayJs = dayjs(new Date(timestamp));

  return !timestamp || lastShownDayJs.add(7,'days').isBefore(dayjs());
}

export const setDiscountModalShownAt = () => {
  setDiscountModalSettings('shownAt', new Date().getTime().toString());
}

export const setDiscountModalActiveTab = (activeTab: string) => {
  setDiscountModalSettings('activeTab', activeTab);
}

export const setSpendNeededTime = () => {
  setDiscountModalSettings('spendEnoughTime', 'true');
}

export const getSpentNeededTimeStatus = () => {
  return getDiscountModalSettings('spendEnoughTime');
}

export const removeSpentNeededTimeStatus = () => {
  removeDiscountModalSettings('spendEnoughTime');
}

export const addToOpenedTabIds = (tabId: string) => {
  try {
    const tabsArr: string[] = JSON.parse(getDiscountModalSettings('openedTabs') || '[]');
    tabsArr.push(tabId);
    const tabsSet = new Set(tabsArr);
    setDiscountModalSettings('openedTabs', JSON.stringify([...tabsSet]));
  } catch (err) {
    setDiscountModalSettings('openedTabs', JSON.stringify([tabId]));
  }
}

export const removeOpenedTabId = (tabId: string) => {
  try {
    const tabsArr: string[] = JSON.parse(getDiscountModalSettings('openedTabs') || '[]');
    const tabsSet = new Set(tabsArr);
    tabsSet.delete(tabId);
    setDiscountModalSettings('openedTabs', JSON.stringify([...tabsSet]));
  } catch (err) {}
}

export const getOpenedTabIds = (): string[] => {
  try {
    return JSON.parse(getDiscountModalSettings('openedTabs') || '[]');
  } catch (err) {
    return [];
  }
}

export const addVisitedPathname = (pathname: string) => {
  try {
    const pathnamesArr: string[] = JSON.parse(getDiscountModalSettings('visitedPathnames') || '[]');
    pathnamesArr.push(pathname);
    const pathnamsSet = new Set(pathnamesArr);
    setDiscountModalSettings('visitedPathnames', JSON.stringify([...pathnamsSet]));
  } catch (err) {
    setDiscountModalSettings('visitedPathnames', JSON.stringify([pathname]));
  }
}

export const getVisitedPathnamesCount = () => {
  try {
    const pathnamesArr: string[] = JSON.parse(getDiscountModalSettings('visitedPathnames') || '[]');
    return pathnamesArr?.length;
  } catch (err) {
    return 0;
  }
}

export const removeVisitedPathname = (pathname: string) => {
  try {
    const pathnamesArr: string[] = JSON.parse(getDiscountModalSettings('visitedPathnames') || '[]');
    const pathnamesSet = new Set(pathnamesArr);
    pathnamesSet.delete(pathname);
    setDiscountModalSettings('visitedPathnames', JSON.stringify([...pathnamesSet]));
  } catch (err) {}
}

export const removeVisitedPathnames = () => {
  removeDiscountModalSettings('visitedPathnames');
}

export const setQuoteSubmitted = () => {
  setDiscountModalSettings('isQuoteSubmitted', 'true');
}

export const getGetQuoteSubmitted = () => {
  return getDiscountModalSettings('isQuoteSubmitted') === 'true';
}

export const removeQuoteSubmitted = () => {
  removeDiscountModalSettings('isQuoteSubmitted');
}

export const setSearchHasResult = () => {
  setDiscountModalSettings('searchHasResult', 'true');
}

export const getSearchHasResult = () => {
  return getDiscountModalSettings('searchHasResult') === 'true';
}

export const removeSearchHasResult = () => {
  removeDiscountModalSettings('searchHasResult');
}

// For backward compatibility
export const addToOpennedTabIds = addToOpenedTabIds;
export const removeOpennedTabId = removeOpenedTabId;
export const getOpennedTabIds = getOpenedTabIds;
