import {  Modal, Spin } from 'antd';
import discountModalImage from 'assets/images/discount-modal.webp';
import classNames from 'classnames';
import { MdOutlineEmail } from "react-icons/md";
import { useState, useContext, useEffect } from "react";
import { SettingContext } from 'core/context/SettingContext';
import { useGetAccessToken, useGetPromotion, useRecordPromo } from 'hooks/api';
import { useDiscountDialog } from 'hooks';
import { LoadingOutlined, StarFilled } from '@ant-design/icons';

export function DiscountDialog() {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');

  const { location } = useContext(SettingContext);
  const { data: accessTokenData } = useGetAccessToken();

  const accessToken = accessTokenData?.data?.accessToken;

  const { data: promotionData } = useGetPromotion({ accessToken });
  const {
    mutate: recordPromo,
    isSuccess: promotionIsSuccess,
    isPending: promotionIsPending,
  } = useRecordPromo({ accessToken });

  const isDiscountOpen = useDiscountDialog();

  const promotion = promotionData?.data?.promotions?.[0];
  const discountLabel = (
    promotion?.params?.["FIXED"] && `$${promotion.params["FIXED"]}`
  ) || (
    promotion?.params?.["VARIABLE"] && `${promotion.params["VARIABLE"]}%`
  ) || '10%';

  const onClose = () => setOpen(false);

  const submitEmail = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    recordPromo({
      promotionId: promotion?.referenceId,
      userEmail: email,
      city: location?.obj?.name,
    });
  };

  useEffect(() => {
    setOpen(isDiscountOpen);
  }, [isDiscountOpen]);

  useEffect(() => {
    if (promotionIsSuccess) {
      setTimeout(onClose, 10000);
    }
  }, [promotionIsSuccess]);

  return (
    <Modal
      centered
      onCancel={onClose}
      footer={null}
      width={800}
      open={open && Boolean(promotion)}
      destroyOnClose={true}
      className="overflow-auto rounded-6 [&_.ant-modal-content]:p-0 adeltest"
    >
      <div className="flex flex-col md:flex-row h-full">
        <div className="relative w-full md:w-1/2 bg-gray-200">
          <img
            src={discountModalImage}
            alt="Trailer"
            className="object-cover w-full h-275 md:h-full"
            loading="lazy"
          />
          <div className="absolute bottom-30 left-0 text-white p-2 text-center w-full">
            <h1 className="font-montserrat font-extrabold text-64">{discountLabel} OFF</h1>
            <p className="font-montserrat text-sm font-medium md:text-xl text-neutral-2">The most trusted marketplace</p>
          </div>
        </div>

        <div className="flex flex-col items-center md:justify-center w-full md:w-1/2 gap-4 bg-white relative p-18 md:pt-80 pb-20">
        {
          promotionIsSuccess ? (
            <div className="flex flex-col items-center md:justify-start w-full" >
              <svg width="84" height="80" viewBox="0 0 84 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M63.7088 11.8479C64.8452 10.4984 64.6793 8.47148 63.2435 7.44628C56.796 2.84261 49.1037 0.242648 41.1367 0.0161543C32.1019 -0.240697 23.2464 2.56994 16.0133 7.99002C8.78026 13.4101 3.5961 21.1201 1.30565 29.8636C-0.984801 38.607 -0.246507 47.8685 3.40022 56.1386C7.04694 64.4088 13.3871 71.2001 21.3875 75.4059C29.3879 79.6116 38.5768 80.9838 47.4569 79.2988C56.3369 77.6138 64.3845 72.971 70.2882 66.127C75.4942 60.0919 78.7625 52.6589 79.7118 44.7937C79.9232 43.0421 78.5385 41.5526 76.7757 41.4816C75.0129 41.4105 73.5419 42.786 73.3039 44.5341C72.4298 50.9555 69.7132 57.0124 65.4505 61.954C60.4898 67.7048 53.7275 71.606 46.2658 73.0219C38.8041 74.4377 31.0829 73.2847 24.3603 69.7507C17.6378 66.2168 12.3103 60.5102 9.24604 53.5609C6.18178 46.6117 5.56141 38.8295 7.48602 31.4826C9.41063 24.1357 13.7668 17.6571 19.8445 13.1027C25.9223 8.54837 33.3634 6.18666 40.9551 6.40249C47.4785 6.58794 53.7827 8.66711 59.1134 12.3525C60.5647 13.3558 62.5723 13.1973 63.7088 11.8479Z" fill="#F8D532"/>
                <path d="M80.0036 12.4863L40.8996 51.7927L23.125 33.9262" stroke="#F8D532" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
                <h2 className="font-extrabold font-montserrat text-2xl md:text-32 leading-[140%] text-center text-[#444] mt-16">Your discount code is sent!</h2>
                <span className="font-montserrat text-sm font-normal text-center text-[#9B9B9B] mt-4">Your discount code has been sent to your email! Start exploring the largest trailer rental marketplace to find the perfect trailer for your needs.</span>
                <button
                  className="mt-20 text-[#9B9B9B] border-[1px] hover:text-[#444] hover:border-[#9B9B9B] rounded-md py-4 px-16 text-16"
                  onClick={onClose}
                >
                  Continue Browsing
                </button>
            </div>
          ) : (
            <>
              <h2 className="font-extrabold font-montserrat text-2xl md:text-32 leading-[140%] text-center text-neutral-90">Get a discount on your first rental!</h2>
              <p className="font-montserrat text-sm text-center text-neutral-60 font-normal leading-[18px] mt-8">
                We are the best trailer marketplace.
                Enjoy top-rated service and get {discountLabel} off your first rental.
                Enter your email now to claim your savings.
              </p>
              <form
                onSubmit={submitEmail}
                className="flex flex-col items-center w-full mb-50 md:mb-0 mt-16 md:mt-32 gap-12 relative"
              >
                <input
                  type="email"
                  placeholder="Enter your email here"
                  className="text-neutral-32 pl-27 pr-8 py-8 border-2 border-neutral-30 outline-none w-full rounded-8"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <MdOutlineEmail className="absolute text-neutral-60 text-base top-[13px] left-[6px]" />
                <button
                  type="submit"
                  className="relative rounded-8 py-8 w-full flex items-center justify-center font-montserrat font-bold bg-gradient-to-r from-[#F6CC08] to-[#FBEA9A] border-none text-black hover:bg-gradient-to-r hover:from-[#F6CC08] hover:to-[#FBEA9A] hover:text-black"
                  disabled={promotionIsPending}
                >
                  <span className={classNames(promotionIsPending && 'text-boulder')}>Email My Discount</span>
                  {
                    promotionIsPending && (
                      <Spin
                        className="absolute"
                        indicator={(
                          <LoadingOutlined
                            className="text-black"
                            spin
                          />
                        )}
                      />
                    )
                  }
                </button>
              </form>
            </>
          )
        }
          <div className="md:pt-80 text-neutral-80">
            <StarFilled className="mr-6 text-supernova" />
            Rated 4.8/5 by thousands of customers.
          </div>
        </div>
      </div>
    </Modal>
  );
}
